//helpers
.c-orange{
    color: $orange;
}

#page-immo-list{

    //Banner
    .banner{
		&__text{
			margin: 0 auto;
		}
    }
    
    .immo-list{
        .immo-card{
            margin-bottom: 25px;
        }
    }
    //Card
    .immo-card{
        a{
            color: $black;
        }
        .image{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            border-radius: 4px 4px 0 0;

            @media (max-width: $lg) {
                height: 300px;
            }
        }

        .content{
            padding: 30px;
            border-bottom: 1px solid rgba($orange, .4); 

            @media (max-width: $lg) {
                border-top: 0;
            }

            //Caracteristics
            .caracteristics{
                display: flex;
                align-items: center;

                margin-bottom: 15px;

                .price{
                    @extend .c-orange;

                    margin-left: 10px;

                    display: inline-flex;
                    align-items: center;

                    &:before{
                        content: "";
                        width: 15px;
                        height: 1px;

                        margin-right: 5px;
                        background-color: $orange;
                    }
                }
            }

            //Title
            .immo-title{
                width: 80%;

                font-size: 22px;
                font-weight: 200;

                @media (max-width: $lg) {
                    width: 100%;
                }
            }

            //Location
            .location{
                @extend .c-orange;
            }

            //Excerpt
            .excerpt{
                margin: 25px 0;
            }

            .link{
                display: inline-block;
            }
        }
    }
}