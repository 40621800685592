.blog{
	.banner{
		.title{
			&::first-letter{
				color: $orange;
			}
		}
		&__text{
			margin: 0 auto;
		}
	}
	
	.load-more-container{
		margin-top: 15px;
		text-align: center;
	}
}