//_burger.scss
.burger{
	width: 40px;
	height: 35px;
	margin: 0 10px;
	position: fixed;
	top: 32px;
	right: 20px;
	display: none!important;
	overflow: hidden;
	cursor: pointer;
	z-index: 999;
	@media (max-width: $xl) { 
		display: inline-block!important;
	}
	&-element{
		width: 40px;
		height: 3px;
		margin: 5px 0;
		display: block;
		position: absolute;
		left: 0px;
		right: 0px;
		border-radius: 10px;
		background-color: $orange;
		transition: all 0.4s;
		&:first-child{
			top: 5px;
			transform:rotateZ(0deg);
		}
		&:nth-child(2),
		&:nth-child(3){
			top: 15px;
			left: 0;
		}
		&:last-child{
			top: 25px;
			transform:rotateZ(0deg);
		}
  	}
}
.burger.active{
	.burger-element{
		top:15px;
	    &:first-child{
	      transform:rotateZ(32deg);
	    }
	    &:last-child{
	      transform:rotateZ(-32deg);
	    }
	    &:nth-child(2){
		  	left: -100%;
		}
		&:nth-child(3){
		  	left: 100%;
		}
	}
}