//_banner.scss
.banner{

	&--big{
		width: 100%;
		height: 100vh;
		background-attachment: fixed;
	}
	&--mid{
		width: 100%;
		height: 450px;
		background-attachment: fixed;
	}
	&__text{
		width: 60%;
		@media (max-width: $xl) { 
			width: 80%;
		}
		@media (max-width: $md) { 
			padding-top: 60px;
			width: 100%;
			text-align: center;
		}
	}
}