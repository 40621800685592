//_social.scss
.social-share{
	height: 100%;
	justify-content: space-between;
	a{
		color: $white;
		transition: all 0.3s ease;
		&:hover{
			color: $orange;
		}
	}
}
.header-container{
	.social-share{
		justify-content: space-around;
	}
}