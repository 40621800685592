.post-template-default{
	.article-container{
		
		.wrapper{
			max-width: 750px;
			margin: 0 auto;
			position: relative;
		}
		.social-share-article{
			position: absolute;
			top: 0;
			left: -180px;
			width: 150px;
			@media (max-width: $lg) { 
				position: relative;
				left: 0;
			}
			p{
				font-size: 12px;
				font-weight: bold;
				margin-bottom: 20px;
			}
			a{
				width: 32px;
				height: 32px;
				padding-top: 3px;
				text-align: center;
				border: 1px solid lighten($grey, 50%);
				border-radius: 100%;
				&.facebook-share{
					color: #3B5998;
				}
				&.twitter-share{
					color: #1DA1F2;
				}
				&.linkedin-share{
					color: #0077B5;
				}
				&:hover{
					color: $orange;
				}
			}
		}
	}
	
	.article-body{
		strong{
			font-size: 18px;
			font-weight: bold;
		}
		p{
			line-height: 2;
			img{
				margin-top: 80px;
				margin-bottom: 80px;
				width: 100%;
				height: auto;
			}
		}
		img{
			width: 100%!important;
			height: auto;
		}
	}
}