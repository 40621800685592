.image-container{
	background-repeat: no-repeat;
	background-size: cover;
	max-width: 512px;
	@media (max-width: $lg) { 
		height: 400px;
		margin: 0 auto 60px;
	}
}
.simulation-form{
	.gfield{
		padding-right: 0!important;
		padding-left: 30px!important;
		background: url('../../src/img/arrow-orange.svg') no-repeat left top 10px;
	}
	.amount{
		opacity: 0.5;
		transition: all 0.3s ease;
		.gfield_label{
			display: block!important;
			font-weight: 500;
			color: rgba($grey, 0.9);
			padding-left: 5px;
			padding-top: 5px;
			.gfield_required{
				display: none;
			}
		}
		.ginput_container_radio{
			li{
				width: 25%;
				height: 40px;
				position: relative;
				display: inline-block;
				label{
					position: absolute;
					top:0;
					left: 0;
					width: 100%;
					height: 100%;
					display: block;
					padding: 8px 10px;
					margin: 0;
					border: 1px solid $orange;
					background-color: $white;
					color: rgba($grey, 0.9);
					letter-spacing: 0.1;
					transition: all 0.3s ease;
				}
			}
		}
		input[type="radio"]:checked+label{ 
			background-color: $orange; 
			color: $white;
		} 
		input{
			margin-bottom: 4px!important; 
		}
	}
	.gform_footer{
		width: 100%!important;
		text-align: center!important;
		input{
			opacity: 1;
		}
	}
}
