.single-landingpageseo{
	.banner--mid{
		background-size: cover;
	}
	.text-wrapper{
		max-width: 750px;
		margin: 0 auto;
		.seo-title{
			width: 70%;
		}
		p{
			margin-top: 35px;
		}
	}
	.end-page{
		margin-bottom: 40px;
	}
}