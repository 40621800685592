.single-solution{
	[data-aos="zoom-in-right"]{
		opacity: 0!important;
		&.aos-animate {
	    opacity: 0.15!important;
	  }
	}
	[data-aos="fade-op"]{
		opacity: 0!important;
		&.aos-animate {
	    opacity: 1!important;
	  }
	}

	.banner{
		width: 80%;
		@media (max-width: $md) {
			width: 100%;
		}
	}

	.wrapper{
		max-width: 1140px;
	}


	.bg-title{
		position: absolute;
		font-family: "Neurial Grotesk Bold";
		line-height: 1;
		font-size: 150px;
		opacity: 0.15;
		top: -55px;
		right: 15%;
		@media (max-width: $md) {
			font-size: 100px;
			right: 0;
		}
	}

	.solution-content,
	.exemple{
		ul{
			padding-left: 0;
			list-style-type: none;
			li{
				padding-left: 40px;
				position: relative;
				&:before{
					content:'•';
					color: $orange;
					position: absolute;
					left: 0;
				}
			}
		}
		a{
			color: $grey;
			text-decoration: none;
			position: relative;
			padding-bottom: 5px;
			transition: all 0.3s ease;
			&:after{
				content: "";
				height: 1px;
				background-color: $orange;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			&:hover{
				color: $orange;
			}
		}
		.button{
			color: $white;
			margin-top: 20px;
			padding: 15px 24px;
			&:hover{
				color: $white;
			}
		}
	}

	.element{
		position: relative;

		.text-part,
		.image-part {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.image-part{
			@media (max-width: $md) {
				margin-top: 40px;
				display: block;
				text-align: center;
			}
			img{
				width: 100%;
				@media (max-width: $md) {
					width: auto;
				}
			}
		}
		.reverse-element{
			@media (max-width: $md) {
				order: 2;
			}
		}
	}

	.exemple{
		padding-top: 150px;
		.container{
			position: relative;
			max-width: 950px!important;
			.bg-title{
				font-style: italic;
				left: -85px;
				top: -90px;
			}
		}
		[data-aos="fade-in"]{
			opacity: 0!important;
			&.aos-animate {
		    opacity: 0.15!important;
		  }
		}
	}
	.banner-img{
		height: 650px;
	}

	.other-project{
		.other-element{
			width: 460px;
			height: 460px;
			border-radius: 8px;
			position: relative;
			&-mask{
				background-image: url('../../src/img/mask.png');
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.15;
			}
			p{
				position: relative;
				z-index: 10;
				margin-bottom: 40px;
				transform: translate3d( 0, 100%, 0 );
				transition: all 0.5s ease;
			}
			.button{
				opacity: 0;
			}
			&:hover{
				p{
					transform: translate3d( 0, 0, 0 );
				}
				.button{
					opacity: 1;
				}
			}
		}
	}
	.form-part{
		max-width: 560px;
		margin: 0 auto;
		.tel{
			color: $grey;
			transition: all 0.3s ease;
			&:hover{
				color: $orange;
			}
		}
		.gfield_label{
			display: none!important;
		}
		.gform_footer{
			text-align: center;
		}
	}
}
