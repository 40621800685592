.page-template-about-us,
.page-template-team {
  .banner {
    .title--big {
      &::first-letter {
        color: $orange;
      }
    }
  }

  .text-wrapper {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: $md) {
      padding: 0 20px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .image-wrapper {
    margin-top: 150px;
    @media (max-width: $md) {
      margin-top: 60px;
    }

    img {
      width: 100%;
    }
  }

  .image-container {
    height: 430px;
    background-image: url('../../src/img/about/about-value.jpg');
    max-width: 100%;
  }


  .value {

    &:before {
      content: "";
      position: absolute;
      height: 50%;
      width: 100%;

      bottom: 0;
      background: $cwhite;
    }

    position: relative;
    //padding: 0;

    .swiper {
      margin-top: 40px;
      padding-bottom: 50px;
    }

    .container {
      padding: 0 !important;
    }

    .swiper-container {
      padding-bottom: 120px;
      padding-top: 60px;
    }


    // navigation
    .swiper-navigation,
    .swiper-slide {
      padding-left: 100px;
      padding-right: 60px;

      @media (max-width: $lg) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    // pagination
    .swiper-pagination {
      top: auto;
      bottom: 10px;
      background: darken($orange, 35%);
    }

    // progression
    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
      background: $orange;
    }

    // navigation
    .swiper-navigation {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      bottom: 50px;

      padding-left: 20px;
      padding-right: 20px;

      @media (max-width: $md) {
        margin-bottom: 20px;
      }

      .swiper-arrow {
        opacity: 1;

        cursor: pointer;
        display: inline-block;
        width: 40px;
        height: 40px;
        padding: 2px 0;
        border: 2px solid $orange;
        border-radius: 100%;

        &.swiper-prev {
          margin-right: 25px;
        }

        img {
          margin-top: 5px;
        }
      }

      .swiper-button-disabled {
        opacity: 0;
      }
    }

    .quote {
      display: flex;
      margin-top: 80px;

      @media (max-width: $lg) {
        margin-top: 40px;
        margin-bottom: 40px;
      }

      &:before {
        flex: none;

        content: "";
        position: relative;

        width: 45px;
        height: 30px;

        background: url('../../src/img/quote.svg');
        background-size: contain;
        background-repeat: no-repeat;

        margin-top: 5px;
        margin-right: 10px;
      }

      font-size: 20px;
    }

    .slider-names {
      position: absolute;
      width: 100%;
      bottom: 20px;
      z-index: 100;
      display: flex;
      justify-content: space-around;

      a {
        display: inline-block;
        position: relative;
        color: rgba($orange, 0.3);
        transition: all 0.3s ease;

        .before {
          width: 0;
          position: absolute;
          color: $orange;
          overflow: hidden;
          white-space: nowrap;
          transition: all 0.3s;
        }

        &.active,
        &:hover {
          color: $orange;
        }
      }

      @media (max-width: $sm) {
        display: none;
      }
    }

    .swiper-pagination-progressbar {
      bottom: 0;
      height: 8px;
    }

  }


  .expertises {
    .text-wrapper {
      max-width: 750px;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      h2 {
        margin: 0;
      }
    }

    .expertise {
      &:nth-child(even) {
        background: $cwhite;
      }

      .left {
        display: flex;
        align-items: center;

        @media (max-width: $lg) {
          margin-bottom: 50px;

          align-items: flex-end;
        }

        .expertise-number {
          font-family: $bold-family;
          font-size: 100px;
          line-height: 75px;

          color: $orange;

          opacity: .3;
        }

        .title {
          transform: translateX(-10px);

          font-size: 20px;
          line-height: 1.3;

          font-family: $font-family;

          h3 {
            font-family: $bold-family;
            font-size: inherit;
            font-weight: bold;
          }
        }

      }

      .title-content {
        font-family: $bold-family;
      }
    }
  }


  .conseil {
    margin-bottom: 40px;
  }

  .numbers-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .number {
      width: 250px;
      margin-bottom: 65px;
      position: relative;

      &-more {
        position: absolute;
        right: 22%;
        top: 30%;

        &::before {
          content: attr(data-more);
          color: $orange;
          text-transform: uppercase;
        }
      }

      div.compteur {
        float: left;
        display: inline;
        position: relative;
        width: 250px;
        height: 250px;
      }

      div.compteur input {
        position: absolute;
        top: 94px;
        left: 50px;
        font-size: 60px;
        text-align: center;
        width: 100% !important;
        border: 0;
        background: none;
        outline: none;
        line-height: 1em;
        color: $orange;
        font-weight: bold;
        font-style: italic;

      }

      div.compteur canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      }
    }
  }
}

.media-ex,
.media-yt {
  margin-bottom: 120px;

  #player {
    height: 640px;
    border: none;
    @media (max-width: $lg) {
      height: 405px;
    }
    @media (max-width: $md) {
      height: 303px;
    }
  }
}

.plyr {
  margin: auto;
  width: 100%;
  .plyr__control svg {
    fill: $orange;
  }
}

.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #ff9a6b !important;
}
.plyr--full-ui input[type=range]{
  color: #ff9a6b !important;
}