//_base.scss


body{
	font-family: $font-family;
	font-size: 16px;
	font-weight: 300;
	line-height: 1.5;
    overflow-x: hidden;
    
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
}

.wrapper{
	width: 100%;
	max-width: 1420px;
	margin: 0 auto;
	padding: 0 20px;
}

/*
Title
*/
.title{
	font-family: $bold-family;
	&--big{
		font-size: 56px;
		@extend .title;
		@media (max-width: $lg) { 
			font-size: 50px;
		}
		@media (max-width: $md) { 
			font-size: 46px;
		}
		@media screen and (max-width: $sm) { 
			font-size: 32px;
		}
	}
	&--medium{
		font-size: 40px;
		line-height: 56px;
		@extend .title;
		@media screen and (max-width: $sm) { 
			font-size: 28px;
			line-height: 32px;
		}
	}
	&--little{
		font-family: $font-family;
		font-size: 32px;
		font-weight: bold;
		line-height: 40px;
		@extend .title;
		margin-bottom: 30px;
	}
}

.subtitle{
	font-family: $light-family;
	font-size: 24px;
	@media screen and (max-width: $sm) { 
		font-size: 20px;
	}
}

.first-letter-color::first-letter{
	color: $orange;
}

/*
Text
*/
.text{
	font-size: 16px;
	font-weight: 300;
	color: $grey;
	line-height: 2;
}
strong{
	font-weight: bold;
}
em{
	font-style: italic;
}

/*
Background
*/

@each $key, $color in $colors{
	.color--#{$key}{
		color: $color;
	}
	.bg--#{$key}{
		background-color: $color;
	}
}

@each $key, $fw in $font-weight{
	.f--#{$key}{
		font-weight: $fw;
	}
}

@each $key, $fs in $font-size{
	.font--#{$key}{
		font-size: $fs;
	}
}

.bg-img{
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	&--filter{
		@extend .bg-img;
		&::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($black, 0.35);
			z-index: 0;
		}
		&>*{
			position: relative;
			z-index: 1;
		}
	}
}

/*
Text
*/
strong{
	// color: $red;
}

/*
Link
*/
a{
	&:hover{
		text-decoration: none;
	}
}

/*
List
*/


/*
Buttons
*/

.button{
	letter-spacing: 1px;
	position: relative;
	z-index: 2;
	border-top: 1px solid $orange;
	border-bottom: 1px solid $orange;
	border-radius: 30px;
	transition: all 0.5s;
	overflow: hidden;
	background-color: $orange;
	display: inline-block;
	
	span{
		position: relative;
		z-index: 10;
	}

	&--transparent{
		background-color: transparent;
		border: 1px solid $orange;
		&:hover{
			background-color: $orange;
			color: $white;
		}
	}

	&--medium{
		padding: 15px 24px;
		@extend .button;
	}
	&--shadow{
		@extend .button;
		box-shadow: 0px 6px 16px rgba($orange, 0.5);
		&:hover{
			box-shadow: 0 2px 5px rgba($orange, 0.5);
		}
	}
	&--black-shadow{
		@extend .button;
		box-shadow: 0px 6px 16px rgba($black, 0.5);
		&:hover{
			box-shadow: 0 2px 5px rgba($black, 0.5);
		}
	}
	&:hover{
		color: $white;
		transform: scale(0.95);
	}
}



.abs-100{
	position: absolute;
	width: 100%;
	height: 100%;
	&--tl{
		@extend .abs-100;
		top: 0;
		left: 0;
	}
	&--tr{
		@extend .abs-100;
		top: 0;
		right: 0;
	}
	&--bl{
		@extend .abs-100;
		bottom: 0;
		left: 0;
	}
	&--br{
		@extend .abs-100;
		bottom: 0;
		right: 0;
	}
}

/*
paddings
*/
@each $key, $padding in $paddings{
	.p--#{$key}{
		padding-top: $padding;
		padding-bottom: $padding;
		@media (max-width: $lg){
			padding-top: $padding/1.3;
			padding-bottom: $padding/1.3;
		}
		@media (max-width: $md){
			padding-top: $padding/1.8;
			padding-bottom: $padding/1.8;
		} 
	}
	.pb--#{$key}{
		padding-bottom: $padding;
		@media (max-width: $lg){
			padding-bottom: $padding/1.3;
		}
		@media (max-width: $md){
			padding-bottom: $padding/1.8;
		}
	}
	.pt--#{$key}{
		padding-top: $padding;
		@media (max-width: $lg){
			padding-top: $padding/1.3;
		}
		@media (max-width: $md){
			padding-top: $padding/1.8;
		}
	}
}



/*
Astérisques
*/
.asterisk{
	font-size: 14px;
}

.jc-center{
    justify-content: center;
}