/*!
Theme Name: Adveris Starter Theme
Author: Eddy SAMY
Description: Starter Theme with Bootstrap to use with Timber
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Adveris
Tags:

*/

/*
|
| Bootstrap
|---------------
| ce ciljmlkmkfs
|
*/

/* ==========================================================================
   Bootstrap
   ========================================================================== */
@import '../../node_modules/bootstrap/scss/bootstrap';

/* ==========================================================================
   Base
   ========================================================================== */
@import "base/reset";
@import "base/mixins";
@import "base/variables";
@import "base/base";
@import "base/animations";


/* ==========================================================================
   Modules
   ========================================================================== */
@import "modules/header";
@import "modules/nav";
@import "modules/social";
@import "modules/banner";
@import "modules/footer";
@import "modules/actu";
@import "modules/contact-banner";
@import "modules/other-projects";
@import "modules/soluce";
@import "modules/landing-list";
@import "modules/form";

/* ==========================================================================
   Components
   ========================================================================== */
@import "components/burger";
@import "components/bg-title";
@import "components/more";
@import "components/cta";
@import "components/progress";
@import "components/team";

/* ==========================================================================
   Pages
   ========================================================================== */
@import "pages/index";
@import "pages/about";
@import "pages/blog";
@import "pages/article";
@import "pages/contact";
@import "pages/thematique";
@import "pages/solution";
@import "pages/seo";
@import "pages/simulation";
@import "pages/sea";
@import "pages/simulateur";
@import "pages/results-simulation";
@import "pages/basic-page";
@import "pages/imo-list";
@import "pages/immo-single";
