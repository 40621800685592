//_header.scss

.header-container {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	padding: 32px 20px;

	.navigation-container {
		@media (max-width: 991px) {
			order: 2;
		}
	}

	.logo-container {
		.logo {
			&-show {
				display: block;
			}

			&-hidden {
				display: none;
			}
		}
	}

	.logo {
		padding: 0;
	}

	.social-share-container {
		@media (max-width: $xl) {
			display: none;
		}
	}
}

.page-template-team .header-container,
.blog .header-container,
.page-template-imo-list,
.page-template-imo-list-location,
.error404 .header-container,
.page-id-26 .header-container, .page-id-2974,
.page-template-simulation .header-container,
.single-landingpagesea .header-container {
	.navigation {
		> li {
			> a {
				color: $grey;
				transition: all 0.3s ease;

				&:hover {
						color: $orange!important;
				}
			}

			&:last-child {
				a {
					color: $white;
					text-transform: uppercase;
				} 
			}
		}
	}

	.social-share {
		a {
			color: $grey;

			&:hover {
				color: $orange;
			}
		}
	}
}

.page-template-template-simulateur {
	.navigation > li:not(:last-child) > a {
		color: $grey;
	}
}
