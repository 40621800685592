.landing-list{
	margin-top: 45px;
	padding-left: 0;
	list-style-type: none;
	a{
		color: $grey;
		text-decoration: none;
		position: relative;
		padding-bottom: 5px;
		transition: all 0.3s ease;
		&:after{
			content: "";
			height: 1px;
			background-color: $orange;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		
		&:hover{
			color: $orange;
		}

	}
}