//_index.scss
.home{

    .highlight{

        .jc-between{
            justify-content: space-between;
        }

        .item{
            text-align: center;

            font-weight: light;
			padding: 0 40px; 

            @media (max-width: $lg) {
                margin-bottom: 50px;
            }
            
            .quote{
                position: relative;

                &:after, &:before{
                    content: "";
                    
                    background-image: url('../../src/img/quote-black.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;

                    width: 24px;
                    height: 24px;

                    position: absolute;

                    opacity: .5;


                }
                &:before {
                    left: -34px;
                    top: -2px;

                    @media (max-width: $lg) {
                        left: 0;
                        top: -24px;
                    }
                }
                &:after{
                    right: -34px;
                    top: -2px;

                    @media (max-width: $lg) {
                        right: 0;
                        top: 60px;
                    }

                    transform: rotate(180deg); 
                }
            }

            .image{

                height: 60px;
                width: 150px;

                margin: 15px auto 0;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

            }
        }
    }

	.patrimonial-part{
		.swiper-home-image{
			.image-part{
				height: 550px;
				@media (max-width: $lg) {
					height: 400px;
				}
			}
		}
		.container{
			@media (max-width: $lg) {
				max-width: 100%;
				width: 100%;
			}
		}
	}
	.text-wrapper{
		max-width: 750px;
		width: 100%;
		margin: 0 auto;
		@media (max-width: $md) {
			padding: 0 20px;
		}
		p{
			margin-bottom: 0;
		}
	}
	.patrimonial-slider{
		position: relative;
		margin-top: -50px;
		padding: 0;
		z-index: 100;
		.swiper-container{
			padding-bottom: 120px;
			padding-top: 60px;
		}

		ul{
			padding-left: 0;
			list-style-type: none;
			li{
				padding-left: 20px;
				position: relative;
				&:before{
					content:'•';
					color: $orange;
					position: absolute;
					left: 0;
				}
				a{
					color: $white;
					transition: all 0.3s ease;
					&:hover{
						color: $orange;
					}
				}
			}
		}

		// navigation
		.swiper-navigation,
		.swiper-slide{
			padding-left: 60px;
			padding-right: 60px;
			@media (max-width: $lg) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
		// pagination
		.swiper-pagination{
			top: auto;
			bottom: 10px;
			background: darken($orange, 35%);
		}
		// progression
		.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
			background: $orange;
		}
		// navigation
		.swiper-navigation{
			@media (max-width: $md) {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;
			}
			.swiper-arrow{
				display: inline-block;
				width: 40px;
				height: 40px;
				padding: 2px 0;
				border: 2px solid $orange;
				border-radius: 100%;
				&.swiper-prev{
					margin-right: 25px;
				}
			}
		}

		.listing{
			column-count: 2;
			@media (max-width: $md) {
				margin: 40px 0;
			}
			li{
				&:before{
					margin-right: 5px;
				}
			}
		}
		.slider-names{
			position: absolute;
			width: 100%;
			bottom: 20px;
			z-index: 100;
			display: flex;
			justify-content: space-around;
			a{
				display: inline-block;
				position: relative;
				color: rgba($orange, 0.3);
				transition: all 0.3s ease;
				.before{
					width: 0;
					position: absolute;
					color: $orange;
					overflow: hidden;
					white-space: nowrap;
					transition: all 0.3s;
				}
				&.active,
				&:hover{
					color: $orange;
				}
			}
			@media (max-width: $sm) {
				display: none;
			}
		}
	}
	.about{
		.text-container{
			padding: 170px 0;
			@media (max-width: $lg) {
				padding: 80px 0;
			}
			@media (max-width: $md) {
				padding: 85px 20px;
				order: 2;
			}
		}
		.image-part{
			background-image: url('../../src/img/bg/about.jpg');
			@media (max-width: $md) {
				height: 400px;
			}
		}
    }
    


	.why-us{
        &:before {
            content: "";
            position: absolute;
            height: 70%;
            width: 100%;

            bottom: 0;
            background: $cwhite;
        }
        .why-title{
            text-align: center;
        }
        .patrimonial-slider{
            margin-top: 0;
        }


        position: relative;

        .swiper {
            padding-bottom: 50px;
        }

        .container {
            padding: 0 !important;
        }

        .swiper-container {
            padding-bottom: 120px;
            padding-top: 60px;
        }


        // navigation
        .swiper-navigation,
        .swiper-slide {
            padding-left: 100px;
            padding-right: 60px;

            @media (max-width: $lg) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        // pagination
        .swiper-pagination {
            top: auto;
            bottom: 10px;
            background: darken($orange, 35%);
        }

        // progression
        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
            background: $orange;
        }

        // navigation
        .swiper-navigation {
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 100%;
            bottom: 50px;

            padding-left: 20px;
            padding-right: 20px;

            @media (max-width: $md) {
                margin-bottom: 20px;
            }

            .swiper-arrow {
                opacity: 1;

                cursor: pointer;
                display: inline-block;
                width: 40px;
                height: 40px;
                padding: 2px 0;
                border: 2px solid $orange;
                border-radius: 100%;

                &.swiper-prev {
                    margin-right: 25px;
                }

                img {
                    margin-top: 5px;
                }
            }

            .swiper-button-disabled {
                opacity: 0;
            }
        }

        .quote {
            display: flex;
            margin-top: 80px;

            @media (max-width: $lg) {
                margin-top: 40px;
                margin-bottom: 40px;
            }

            &:before {
                flex: none;

                content: "";
                position: relative;

                width: 45px;
                height: 30px;

                background: url('../../src/img/quote.svg');
                background-size: contain;
                background-repeat: no-repeat;

                margin-top: 5px;
                margin-right: 10px;
            }

            font-size: 20px;
        }

        .slider-names {
            position: absolute;
            width: 100%;
            bottom: 20px;
            z-index: 100;
            display: flex;
            justify-content: space-around;

            a {
                display: inline-block;
                position: relative;
                color: rgba($orange, 0.3);
                transition: all 0.3s ease;

                .before {
                    width: 0;
                    position: absolute;
                    color: $orange;
                    overflow: hidden;
                    white-space: nowrap;
                    transition: all 0.3s;
                }

                &.active,
                &:hover {
                    color: $orange;
                }
            }

            @media (max-width: $sm) {
                display: none;
            }
        }

        .swiper-pagination-progressbar {
            bottom: 0;
            height: 8px;
        }
    }
    
	.focus{
		padding: 60px;
		@media (max-width: $lg) {
			padding: 60px 0;
		}
		.text-part{
			@media (max-width: $md) {
				order: 2;
			}
		}
		.image-part{
			background-image: url('../../src/img/bg/focus.jpg');
			@media (max-width: $md) {
				height: 400px;
				margin-bottom: 40px;
			}

		}
	}
	.article{
		.text-part{
			border: 0;
		}
	}
	.listing{
		padding-left: 0;
		list-style-type: none;
		li{
			padding-left: 20px;
			position: relative;
			&:before{
				content:'•';
				color: $orange;
				position: absolute;
				left: 0;
			}
		}
	}
	.link{
		transition: all 0.3s ease;
		&:hover{
			color: $orange;
		}
	}
}
