[data-aos="fade-up"]{
	transform: translateY(50px)!important;
	&.aos-animate {
      transform: translateY(0)!important;
    }
}

[data-aos="fade-down"]{
	transform: translateY(-50px)!important;
	&.aos-animate {
      transform: translateY(0)!important;
    }
}

[data-aos="fade-right"]{
	opacity: 0!important;
	&.aos-animate {
      opacity: 0.15!important;
    }
}

.header-container{
	[data-aos="fade-right"]{
		opacity: 0!important;
		&.aos-animate {
	      opacity: 1!important;
	    }
	}
}

.page-template-contact{
	[data-aos="fade-right"]{
		&.aos-animate {
	      opacity: 1!important;
	    }
	}
}
