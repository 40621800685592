/* =============
-----
*
PROGRESS BAR
*
-----
/ ============*/

.progress--status {
    position: absolute;
    top: -4px;
    left: 0;
    width: 100%;

    .step--list {
        padding: 0;
        display: flex;
        flex-direction: row;

        li {
            list-style: none;
            width: 100%;
            height: 8px;
            background-color: #D8D8D8;
            position: relative;
            opacity: 0.5;

            &.current--step, &.past--step {
                background-color: $orange;
                opacity: 1;
                border-color: $white;

                .step--number {
                    color: $white;
                    background-color: $orange;
                    border-color: $white;
                }
            }

            &:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            .step--number {
                width: 30px;
                height: 30px;
                background-color: $white;
                border: solid 3px $orange;
                color: #54575A;
                border-radius: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
            }
        }
    }
}
