.single-thematique {

  .image-part {
    img {
      width: 100% !important;
      @media (max-width: $md) {
        width: 100% !important;
      }
    }
  }

  .banner {
    .subtitle {
      margin-bottom: 40px;
    }
  }

  .element:nth-child(odd) {
    .text-container {
      order: 2;
    }
  }

  .soluce-link {
    margin-top: 60px;
  }
  .form-part {
    max-width: 560px;
    margin: 0 auto;
    .tel {
      color: $grey;
      transition: all 0.3s ease;
      &:hover {
        color: $orange;
      }
    }
    .gfield_label {
      display: none !important;
    }
    .gform_footer {
      text-align: center;
    }
  }

  .description {
    &-title {
      margin-top: 40px;
    }
    &-text {

      ul {
        padding-left: 0;
        list-style-type: none;
        li {
          padding-left: 20px;
          position: relative;
          &:before {
            content: '•';
            color: $orange;
            position: absolute;
            left: 0;
          }
        }
      }
    }
    .text {
      margin-bottom: 50px;
    }
  }

  .dual-buttons {
    .buttons-container {
      a:last-child {
        margin-left: 15px;
      }
    }
  }

  .formula {

    position: relative;

    &:after {
      content: "";
      z-index: -1;
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;
      height: 60%;

      background: $cwhite;
    }

    .title--big {
      font-size: 160px;
      line-height: 75px;

      margin-left: -20px;

      opacity: 0.2;

      @include media-breakpoint-down(md) {
        font-size: 90px;
        line-height: 35px;
        margin-left: 0;
      }
    }

    .suivi {
      font-size: 56px;
      font-weight: bold;
      font-family: $bold-family;
      color: $grey;
      @include media-breakpoint-down(md) {
        line-height: 1;
      }
    }

    .suivi-list {
      ul {
        padding: 0;

        li {
          list-style: none;

          display: flex;
          align-items: center;

          padding-bottom: 10px;
          &:before {
            content: "";
            width: 18px;
            height: 18px;

            background-image: url('../../src/img/tick.svg');
            background-repeat: no-repeat;
            background-size: contain;

            display: block;

            margin-right: 5px;
          }
        }
      }
    }

    .all-formulas {
      .item {
        padding: 50px 30px;
        .title--medium {
          font-size: 26px;
        }
        .list {
          ul {
            padding: 0;
            li {
              display: flex;
              &:before {
                content: "•";
                position: relative;
                margin-right: 2px;
                color: $orange;
              }
              list-style: none;
            }
          }
        }
      }
    }

  }

}
