.bg-title{
	position: absolute;
	font-family: "Neurial Grotesk Bold";
	line-height: 1;
	font-size: 150px;
	opacity: 0.15;
	top: -55px;
	right: 15%;
	@media (max-width: $md) { 
		font-size: 100px;
		right: 0;
	}
} 
