.page-template-contact,
.page-template-simulation{
	.little-wrapper{
		max-width: 1140px;
		margin: 0 auto;
	}

	.title{
		line-height: 1;
		margin-bottom: 120px;
		@media (max-width: $md) { 
			margin-bottom: 40px
		}
	}

	.form-part{
		.gform_body{
			.gfield_label{
				display: none;
			}
			input,
			textarea{
				width: 80%;
			}

			input,
			select{
				border: 0;
				border-bottom: 1px solid $orange;
				margin-bottom: 20px;
			}
			input ::placeholder,
			select{
				color: $grey!important;
			}

			select {
				width: 100%;
			    -webkit-appearance: none;
			    -moz-appearance: none;
			    text-indent: 1px;
			    text-overflow: '';
			    background-color: transparent;
			    position: relative;
			    z-index: 1;
			    padding: 0;
			}
			.ginput_container_select{
				position: relative;
				width: 80%;
				&::after{
			    	content: '\f107';
			    	font-family: 'fontAwesome';
			    	color: $orange;
			    	position: absolute;
			    	top: 0;
			    	right: 0;
			    	z-index: 0;
			    }
		    }
			textarea{
				border: 1px solid $orange;
				border-radius: 5px;
			}
			
		}
		.gform_footer{
			width: 80%;
			text-align: right;
			.gform_button{
				@extend .button;
				padding: 15px 32px;
				box-shadow: 0 0 0 transparent;
				color: $white;
				border-radius: 25px;
				border: 0;
				cursor: pointer;
			}
		}
	}

	.little-subtitle{
		font-size: 18px;
		line-height: 1;
		margin-top: 40px;
		margin-bottom: 32px;
		font-weight: bold;
	}
	.list{
		padding-left: 0;
		list-style-type: none;
		li{
			&:before{
				content: '•';
				color: $orange;
				margin-right: 20px;
			}
		}
	}
	.informations{
		.address,
		.mail,
		.tel{
			margin-bottom: 0;
			a{
				color: $grey;
				transition: all 0.3s ease;
				&:hover{
					color: $orange;
				}
			}
		}
	}
}