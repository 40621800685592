.landingpagesea-template-default{

	.banner{
		width: 80%;
		@media (max-width: $md) { 
			width: 100%;
		}
	}

	.wrapper{
		max-width: 1140px;
	}

	.bg-title{
		position: absolute;
		font-family: "Neurial Grotesk Bold";
		line-height: 1;
		font-size: 150px;
		opacity: 0.15;
		top: -55px;
		right: 15%;
		@include media-breakpoint-down(md) { 
			font-size: 50px;
			position: relative;
			top: auto;
			right: auto;
		}
	}

	.solution-content,
	.exemple{
		ul{
			padding-left: 0;
			list-style-type: none;
			li{
				padding-left: 40px;
				position: relative;
				&:before{
					content:'•';
					color: $orange;
					position: absolute;
					left: 0;
				}
			}
		}
		a{
			color: $grey;
			text-decoration: none;
			position: relative;
			padding-bottom: 5px;
			transition: all 0.3s ease;
			&:after{
				content: "";
				height: 1px;
				background-color: $orange;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			&:hover{
				color: $orange;
			}
		}
		.cta{
			margin-top: 40px;
			color: $white;
			padding: 15px 24px;
			&:hover{
				color: $white;
			}
		}
	}

	.element{
		position: relative;
		
		.text-part,
		.image-part {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.image-part{
			@media (max-width: $md) { 
				margin-top: 40px;
				display: block;
				text-align: center;
			}
			img{
				width: 100%;
			}
		}
		.reverse-element{
			@media (max-width: $md) {
				order: 2;
			}
		}
	}

	.exemple{
		padding-top: 150px;
		@include media-breakpoint-down(md) { 
			padding-top: 40px;
		}

		.container{
			position: relative;
			max-width: 950px!important;
			.bg-title{
				font-style: italic;
				left: -85px;
				top: -90px;
				@include media-breakpoint-down(md) { 
					position: relative;
					top: auto;
					left: auto;
				}
			}
		}
		[data-aos="fade-in"]{
			opacity: 0!important;
			&.aos-animate {
		    opacity: 0.15!important;
		  }
		}
	}
	.banner-img{
		height: 650px;
		.cta{
			margin-top: 80px; 
		}
	}

	.other-project{
		.other-element{
			width: 460px;
			height: 460px;
			border-radius: 8px;
			position: relative;
			&-mask{
				background-image: url('../../src/img/mask.png');
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0.15;
			}
			p{
				position: relative;
				z-index: 10;
				margin-bottom: 40px;
				transform: translate3d( 0, 100%, 0 );
				transition: all 0.5s ease;
			}
			.button{
				opacity: 0;
			}
			&:hover{
				p{
					transform: translate3d( 0, 0, 0 );
				}
				.button{
					opacity: 1;
				}
			}
		}
	}

	.c-banner{
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		align-items: flex-end;
		.introduction{
			padding: 40px 80px;
			margin-top: 80px; 
			@include media-breakpoint-down(lg) { 
				padding: 40px;
				margin-top: 0;
			}

			.intro{
				font-size: 18px;
			}

			.why{
				font-weight: bold;
				font-size: 20px;
				border-top: 2px solid $cwhite;
				padding-top: 40px;
				margin-top: 40px;
			}
			.why-point{
				margin-bottom: 40px;
				li{
					margin-bottom: 20px;
				}
				b{
					font-weight: bold;
				}
			}
		}
	}

	.form-part{
		padding: 40px 0;
		display: flex;
		align-items: center;
		.form-title{
			font-weight: 700;
			font-size: 1.25em;
			margin-bottom: 40px; 
		}
	}	
	.simulation-form{

		.gform_title{
			display: none;
			margin-bottom: 40px;
		}
		.gfield{
			padding-right: 0!important;
			padding-left: 30px!important;
			background: url('../../src/img/arrow-orange.svg') no-repeat left top 10px;
		}
		.amount{
			opacity: 0.5;
			transition: all 0.3s ease;
			.gfield_label{
				display: block!important;
				font-weight: 500;
				color: rgba($grey, 0.9);
				padding-left: 5px;
				padding-top: 5px;
				.gfield_required{
					display: none;
				}
			}
			.ginput_container_radio{
				li{
					width: 25%;
					height: 40px;
					position: relative;
					display: inline-block;
					label{
						position: absolute;
						top:0;
						left: 0;
						width: 100%;
						height: 100%;
						display: block; 
						padding: 8px 5px;
						margin: 0;
						border: 1px solid $orange;
						background-color: $white;
						color: rgba($grey, 0.9);
						letter-spacing: 0.1;
						transition: all 0.3s ease;
					}
				}
			}
			input[type="radio"]:checked+label{ 
				background-color: $orange; 
				color: $white;
			} 
			input{
				margin-bottom: 4px!important; 
			}
		}
		.gform_footer{
			width: 100%!important;
			text-align: center!important;
			input{
				opacity: 1;
			}
		}
	}

	#toTop{
		cursor: pointer;
	}



}

.no-bg{
	background: none !important;
}