//_footer.scss
.agreements-title{
    text-align: center;
}

.agreements-logos{
    flex-wrap: wrap;
    
    .logo{
        height: 75px;
        width: 120px;

        margin: auto;
        
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.footer-container{
	border-top: 1px solid #D8D8D8;
	border-bottom: 1px solid #D8D8D8;
	padding-top: 50px;
	padding-bottom: 50px;
	.logo{
		@media (max-width: $lg){
			text-align: center;
			margin-bottom: 20px;
		}
	}
	.social-share{
		a{
			color: $darkblue;
			&:hover{
				color: $orange;
			}
		}
		@media (max-width: $lg){
			margin-bottom: 20px;
		}
	}
	.navigation-container{
		@include media-breakpoint-down(md){
			order: 2; 
		} 
		.navigation{
			justify-content: space-around!important;
			padding-left: 0;
			@include media-breakpoint-down(sm){
				flex-direction: column;
				margin-top: 20px;
			} 
		}
	}
	
	li{
		a{
			font-weight: bold;
			color: $orange;
			&:hover{
				color: $orange;
			}
		}
	}
}

.second-navigation{
	list-style-type: none;
	padding-left: 0;
	justify-content: space-between;
	@media (max-width: $lg){
		flex-direction: column;
		text-align: center;
	}
	a{
		transition: all 0.3s ease;
		&:hover{
			color: $orange;
		}
	}
	> li{
		font-weight: bold;
		.nav-drop{
			list-style-type: none;
			padding-left: 0;
			justify-content: space-between;
			margin-top: 20px;
			@media (max-width: $lg){
				margin-top: 0;
				margin-bottom: 10px;
			}
			> li{
				font-size: 12px;
				font-weight: 400;
				line-height: 3;
			}
		}
	}
}

.credits{
	border-top: 1px solid #D8D8D8;
	@include media-breakpoint-down(sm){
		font-size: 12px;
	} 
	a{
		margin: 0 10px;
		color: $grey;
		transition: all 0.3s ease;
		&:hover{
			color: $orange;
		}
	}
}

.footer-cities {
    background: $darkblue;
    padding: 50px 15px;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
        text-align: center;

        .row{
            flex-direction: column;
            align-items: center;
        }
    }

    .item{
        width: 140px;
        &:not(last-child){
            margin-right: 40px;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }
        }
    }

    .text-title{
        font-size: 24px;

        @include media-breakpoint-down(md) {
            text-align: center;
            margin-bottom: 15px;
        }
    }

    .city{
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .adress{
        font-size: 12px;
        font-weight: 100;
    }
}