.form-part{
	.gform_wrapper{
		margin: 0!important;
	}
	.gform_body{
		.gfield_label{
			display: none;
		}
		input,
		textarea{
			width: 80%;
			outline: none;
		}

		input,
		select{
			border: 0;
			border-bottom: 1px solid $orange;
			margin-bottom: 20px;
			background-color: transparent;
		}
		input ::placeholder,
		select{
			color: $grey!important;
		}

		select {
			width: 100%;
		    -webkit-appearance: none;
		    -moz-appearance: none;
		    text-indent: 1px;
		    text-overflow: '';
		    background-color: transparent;
		    position: relative;
		    z-index: 1;
		    padding: 0;
		}
		.ginput_container_select{
			position: relative;
			width: 80%;
			&::after{
		    	content: '\f107';
		    	font-family: 'fontAwesome';
		    	color: $orange;
		    	position: absolute;
		    	top: 0;
		    	right: 0;
		    	z-index: 0;
		    }
	    }
		textarea{
			border: 1px solid $orange;
			border-radius: 5px;
		}

		.mid-form,
		.mid-form-right{
			width: 50%!important;
			display: inline-block;

			input{
				width: 100%!important;
			}
		}
		.area{
			margin-top: 25px!important;
		}
		
	}
	.gform_footer{
		width: 80%;
		text-align: right;
		.gform_button{
			@extend .button;
			padding: 15px 32px;
			box-shadow: 0 0 0 transparent;
			color: $white;
			border-radius: 25px;
			border: 0;
			cursor: pointer;
		}
	}
}

.gfield_checkbox {

	li {

		input {
			vertical-align: top !important;
			margin-top: 3px !important;
			margin-right: 5px !important;
		}

		label {
			a {
				color: $orange;
			}
		}
	}
}