#trombi{
    padding-top: 200px;

    .team-item{
        &-image{
            height: 350px;
        }
    }
}

.team{
    .team-item{
        width: 18%;
        margin: 0 1%;

        @media (max-width: 1024px) {
            width: 31%;
            margin: 0 1.16%;
        }

        @media (max-width: 992px) {
            width: 48%;
            margin: 0 1%;
        }

        @media (max-width: 778px) {
            width: 60%;
            margin: 0 20%;
        }
    }
}

.team-item {

    &-image {
        position: relative;
        height: 300px;
        overflow: hidden;
        cursor: pointer;
        background-size: cover;

        @media (max-width: 992px) {
            height: 350px;
            background-size: cover;
        }

        @media (max-width: 778px) {
            height: 300px; 
        }

        .text-hover {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 20px;
            top: 0;
            right: 50%;
            opacity: 0;
            background-color: rgba($orange, 0.7);
            display: flex;
            align-items: flex-end;
            transition: all 0.5s ease;
        }

        &:hover {
            .text-hover {
                right: 0;
                opacity: 1;
            }
        }
    }

    &-text {
        margin-top: 40px;
        margin-bottom: 60px;
        order: 1;

        p {
            margin-bottom: 0;
        }
    }
}