/* =============
-----
*
PROGRESS BAR
*
-----
/ ============*/
.form--container {
  position: relative;
  padding-top: 60px;

  .gfield_consent_label a{
    color: $orange;
    &:hover{
      color: $orange;
      text-decoration: underline;

    }
  }

  #field_7_9 {
    padding: 0 8px;
  }
  #field_7_17{
    padding-right: 0;
  }

  #gform_previous_button_7_10 {
    margin: 0 auto !important;
    display: block;
    left: 50%;
    transform: translateX(-65%);
  }


  .gform_page {
    width: 50%;
    margin: auto;

    @media all and (max-width: 715px){
      width: 100%;
      padding-right: 20px;
      padding-left: 20px;
    }
  }

  .gform_custom_container {
    width: 100%;
  }

  .gfield {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .gfield_label {
    font-size: 32px !important;
    font-family: "Neurial Grotesk Bold",sans-serif;
    text-align: center;
    margin-bottom: 40px;

    @media all and (max-width: 545px){
      font-size: 22px !important;
    }
  }

  input[type="text"] {
    background: none;
    -webkit-appearance: none;
    border: none;
    border-bottom: solid 2px $orange;
    padding: 20px 50px !important;
    width: 100% !important;
    text-align: center;

    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .ginput_container {
    width: 100%;
  }

  .gform_page_footer {
    border-top: none;
    margin-top: 50px;
    text-align: center;
    input.button{
      width: auto!important;
      margin : 0!important;
      min-height: auto!important;
      line-height: 1!important;
    }
  }

  .gfield_required {
    color : $grey;
  }

  .select2-container {
    width: 100% !important;
  }

  .select2-container--default .select2-selection--single {
    background-color: #fff;
    border-radius: 0;
    border: none;
    height: auto;
    padding: 15px;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 58px;
    right: 15px;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    text-align: center;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    width: 15px;
    border: solid 2px $orange;
    border-left-color: transparent;
    border-top-color: transparent;
    height: 15px;
    transform: rotate(45deg) translateY(-15px);
    left: 0;
  }

  .gform_previous_button {
    position: absolute;
    top: 0;
    background: none;
    color: $grey;
    font-weight: normal;
    border-bottom: solid 1px $orange !important;
    border-radius: 0 !important;
    padding: 10px 0 5px 0 !important;
    display: block;
    margin: 0 auto !important;
    left: 50%;
    transform: translateX(-50%);
    width: auto!important;
  }

  .gform_custom_container {
    ul li.gfield {
      margin-left: auto !important;
    }

    li.gfield_html#field_5_17, li.gfield_html#field_4_17, li.gfield_html#field_5_20,
    li.gfield_html#field_7_20 {
      max-width: 50%;
      position: absolute;
      text-align: left;
      font-size: 32px !important;
      font-family: "Neurial Grotesk Bold",sans-serif;
      margin-bottom: 40px;
      @media all and (max-width: 991px) {
        max-width: 100%;
        position: inherit;
      }
      @media all and (max-width: 545px){
        font-size: 22px !important;
      }
    }

    .gform_page_footer {
      max-width: 50%;
      margin-left: auto;

      @media all and (max-width: 991px){
          text-align: center;
          max-width: 100%;
          .gform_next_button{
            display: block;
          }
      }
    }

    .gform_previous_button {
      left: 0;
      @media all and (max-width: 580px){
        left : 30px
      }
    }

    .gfield_label {
      font-family: "Neurial Grotesk",sans-serif;
      margin-bottom: 0;
      font-size: 22px !important;
    }
  }


  //LAST GFORM PAGE ERROR

  .gform_validation_error .gform_body #gform_page_5_4 ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
    max-width: 50% !important;
    @media all and (max-width: 991px) {
      max-width: 100% !important;
    }
  }


  .gform_validation_error .gform_body #gform_page_4_4 ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
    max-width: 50% !important;
    @media all and (max-width: 991px) {
      max-width: 100% !important;
    }
  }
  .gform_validation_error .gform_body #gform_page_7_4 ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half){
    max-width: 50% !important;
    @media all and (max-width: 991px) {
      max-width: 100% !important;
    }
  }




  .ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    #input_7_15_1, #input_7_15_1{
      flex-shrink: 0;
    }
    .gfield_consent_label{
      flex-grow: 1
    }
  }

  .gform_wrapper li.hidden_label input {
    margin-right: 10px;
  }
}
@media (max-width: 1200px) {
  .form--container form {
    width: 100%;
  }

  .progress--status {
    padding: 0 25px;
  }
}
