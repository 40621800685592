.cta-fixed{
	position: fixed;
	bottom: 50px;
	right: 100px;
	@media (max-width: $lg) { 
		bottom: 10px;
		right: 10px;
	}
	@media (max-width: $md) { 
		display: none;
	}
	.button{
		font-size: 14px;
		@media (max-width: $md) { 
			padding: 0;
			width: 40px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			border-radius: 100%;
		}
	}
}

#catapultCookie, .gform_next_button, .gform_previous_button, .gform_button {
	@extend .button, .button--medium,  .color--white;
	padding: 15px 24px !important;
	border-radius: 30px !important; 

	&:active, &:focus {
		box-shadow: none;
		outline: none;
	}
}
