.other-project{
	.other-element{
		width: 460px;
		height: 460px;
		border-radius: 8px;
		position: relative;
		&-mask{
			background-image: url('../../src/img/mask.png');
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.15;
		}
		p{
			position: relative;
			z-index: 10;
			margin-bottom: 40px;
			transform: translate3d( 0, 100%, 0 );
			transition: all 0.5s ease;
		}
		.button{
			opacity: 0;
		}
		&:hover{
			p{
				transform: translate3d( 0, 0, 0 );
			}
			.button{
				opacity: 1;
			}
		}
	}
}