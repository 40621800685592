//_nav.scss
.navigation{
	font-family: $light-family;
	list-style-type: none;
	margin-bottom: 0;
	>li{
		display: inline;
		&:before{
			content:"";
			margin-right: 0;
		}
		>a{
			
			padding: 10px 25px;
			position: relative;
			display: inline-block;
			@media (max-width: 1240px) { 
				padding: 10px 15px;
			}
			&:before{
				content:"";
				display: block;
				position: absolute;
				height: 2px;
				width: 0;
				left: 10%;
				bottom: 0px;
				background-color: $orange;
				transition: all 0.5s ease;
			}
			&:hover{
				color: $white;
				text-decoration: none;
				&:before{
					width: 80%;
				}
			}
		}
	}
	.dropdown-menu{
		border-radius: 0;
		width: 140%;
		padding: 10px 0;
		box-shadow: -5px 1px 18px -9px rgba(62,59,54,.3);
		li {
			line-height: 1.5;
			a{
				color: $black;
				width: 100%;
				padding: 10px 25px;
				display: inline-block;
				transition: all 0.3s ease;
				position: relative;
			}
        }

        li:not(.dropdown-menu-third){
            a{
                &:after {
                    content: "\f105";
                    font-family: "fontawesome";
                    font-size: 16px;
                    position: absolute;
                    right: 35px;
                    bottom: 8px;
                    opacity: 0;
                    transition: all 0.3s ease;
                }

                &:hover {
                    color: $orange;

                    &:after {
                        opacity: 1;
                        right: 25px;
                    }
                }
            }
        }
	}
}


//General Third menu
        .dropdown-menu-third {
            span {
                display: flex;
                align-items: center;
                justify-content: space-between;

                padding-right: 15px;

                @media (max-width: 1240px) {
                    justify-content: center;
                }

                .arrow-item-third {
                    height: 12px;
                    width: 12px;

                    background-image: url('../../src/img/next.svg');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;

                    transform: rotate(90deg);

                    cursor: pointer;
                    
                    @media (max-width: 1240px) {
                        margin-left: 15px;
                    }
                    
                }
            }

            ul {
                padding: 0;
                overflow: hidden;

                li {
                    padding: 0;
                    list-style: none;
                }
            }

        }


.navigation-responsive{
	display: none;
	position: fixed;
	top: 0;
	right: -100%;
	padding: 0;
	background-color: $darkblue;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	list-style-type: none;
	transition: all 0.5s ease;
	@media (max-width: $xl){
		display: flex!important;
	} 
	&.active{
		right: 0;
	}
	>li{
		>a{
			transition: all 0.3s ease;
			padding: 20px;
			font-size: 20px;
			display: inline-block;
			width: 100%;
			text-align: center;
		}
		&:hover{
			a{
				color: $orange;
			}
		}
	}
	.dropdown-menu.show{
		width: 100vw;
		position: relative!important;
		top: 0;
		float: none;
		transform: translate3d(0, 0,0) !important;
		border-radius: none!important;
		border: 0!important;
		margin: 0!important;
		padding: 0!important;
		background-color: transparent!important;
		text-align: center;
		li{
			padding: 5px 0;
		}
		a{
			color: $white;
			&:hover{
				color: $orange;
			}
		}
	}
}

.header-container{
	.navigation{
		justify-content: flex-end;
		padding-left: 0;
		@media (max-width: $xl) { 
			display: none!important;
		}
		> li{
			&:last-child{
				margin-left: 15px;
				a{
					@extend .button;
					padding: 8px 25px 10px;
					vertical-align: bottom;
					text-transform: uppercase;
					font-size: 14px;
				}
			}
		}
	}
}

