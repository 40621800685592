.solution-content{
	ul{
		padding-left: 0;
		list-style-type: none;
		li{
			padding-left: 40px;
			position: relative;
			&:before{
				content:'•';
				color: $orange;
				position: absolute;
				left: 0;
			}
		}
	}
	a{
		color: $grey;
		text-decoration: none;
		position: relative;
		padding-bottom: 5px;
		transition: all 0.3s ease;
		&:after{
			content: "";
			height: 1px;
			background-color: $orange;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		&:hover{
			color: $orange;
		}
	}

	.element{
		position: relative;
		
		.text-part,
		.image-part {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		.image-part{
			@media (max-width: $md) { 
				margin-top: 40px;
				display: block;
				text-align: center;
			}
			img{
				width: 100%;
				@media (max-width: $md) { 
					width: auto;
				}
			}
		}
		.reverse-element{
			@media (max-width: $md) {
				order: 2;
			}
		}
	}
}