.results-simulation{
  .content-items{
    padding: 80px 30px;
  }
  .title{
    font-size: 32px;
    margin: 40px 0;
  }
  .link{
    position: relative;
    &::after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: -9px;
      border-bottom: solid 1px $orange;
    }
    &:hover{
      color: $black;
    }
  }

  .bg-img{
    min-height: 400px;
  }
  .item{
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }

  .item--value{
    line-height: 1;
    display: block;
    font-size: 34px;
    font-weight: bold;
  }

  // Impots

  .number{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    border-radius: 100%;
    border: 3px solid $orange;
    margin-right: 30px;
    span{
      font-weight: bold;
    }
  }
  .mb-md{
    margin-bottom: 50px;
  }

  // Loyer

  .result-loyer{
    position: relative;
    padding-bottom: 34px;
    margin-top: 30px;
    margin-bottom: 50px;
    &::after{
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid #54575A;
      opacity: 0.5;
    }
  }
  .button{
    border: none;
  }
}
.banner-contact{
  background-color: $white;
  text-align: center;
  padding: 80px 30px;
  .title{
    font-size: 40px;
  }
  .bold{
    font-family: "Neurial Grotesk Bold",sans-serif;
    font-weight: bold;
  }
}

.link{
  position: relative;
  margin : 0 5px;
  &:before{
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 0;
    left: 0;
    bottom: -10px;
    background-color: #FE5000;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  &:hover{
    color: $orange;
    &::before{
      width: 100%;
    }
  }
}
