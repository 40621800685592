.actu {
  color: #000;

  .actu-filter-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto 40px auto;

    .actu-filter-list-element {
      color: $orange;
      text-transform: uppercase;
      padding: 5px 15px;
      border-radius: 20px;
      border: 1px solid #e2e2e2;
      margin: 5px;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      transition: background-color .2s ease-out;

      &.active,
      &:hover {
        background-color: #ffdccc;
      }
    }
  }

  .article {
    display: flex;
    flex-flow: column;
    color: #000;

    @media (max-width: $md) {
      margin-bottom: 40px;
    }

    .image-part {
      height: 310px;
      order: 1;
      border-radius: 5px 5px 0 0;
    }

    .text-part {
      padding: 40px 70px 40px 40px;
      order: 1;
      // border: 1px solid $black;
      // border-top: 0;
      border-radius: 0 0 5px 5px;
      @media (max-width: $lg) {
        padding: 40px 20px;
      }

      .date {
        font-size: 14px;
      }

      .title {
        margin-bottom: 15px;
        font-size: 23px;
        line-height: 30px;
        color: #000;
      }

      .text {
        margin-bottom: 25px;
      }

      a {
        &:hover {
          color: $orange;
        }
      }
    }

    &:nth-child(1n+2) {
      .image-part {
        border-radius: 0 0 5px 5px;
        @media (max-width: $md) {
          border-radius: 5px 5px 0 0;
        }
      }

      .text-part {
        // border-bottom: 0;
        // border-top: 1px solid $black;
        border-radius: 5px 5px 0 0;
        @media (max-width: $md) {
          // border-bottom: 1px solid $black;
          // border-top: 0;
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}