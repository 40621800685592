#page-immo-single {

  /*
  |
  | Banner
  | ---------
  */
  .banner--big {
    height: 90vh;
    position: relative;

    .back-link {
      display: inline-flex;
      align-items: center;
      position: relative;

      margin-bottom: 35px;

      font-size: 14px;
      line-height: 1;

      color: $white;

      &:before {
        content: "";

        margin-right: 10px;

        height: 14px;
        width: 14px;

        transform: rotate(-180deg);

        background-image: url('../../src/img/next.svg');
        background-size: contain;

        transition: .3s all;
      }

      &:after {
        content: "";

        position: absolute;
        left: 0;
        bottom: -8px;

        height: 1px;
        width: 100%;

        background: $orange;
      }

      &:hover {
        &:before {
          margin-right: 5px;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 0;

      top: 0;
      left: 0;

      height: 100%;
      width: 100%;

      background-color: rgba($black, .5);
    }

    .banner-inner {
      z-index: 1;

      .title {
        line-height: 1.2;
      }

      .localisation {
        color: #fff;
        font-weight: 100;
        font-size: 32px;
      }
      .subtitle {
        margin-top: 25px;
        color: #FE5000;
        font-weight: 600;
        font-size: 100px;
      }
    }
  }

  /*
  |
  | Content
  | -------
  */
  .immo-content {

    .items-caracteristics {

      padding-top: 10px;

      @media (max-width: $lg) {
        display: flex;
        justify-content: space-around;
      }

      .item {
        margin-bottom: 20px;

        @media (max-width: $lg) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &.rooms {
          .icon {
            background-image: url('../../src/img/rooms.svg');
          }
        }

        &.bedrooms {
          .icon {
            background-image: url('../../src/img/bedrooms.svg');
          }
        }

        &.square-feet {
          .icon {
            background-image: url('../../src/img/squarefeet.svg');
          }
        }

        &.square-feet-price {
          .icon {
            background-image: url('../../src/img/price.svg');
          }
        }

        .icon {
          margin-bottom: 5px;

          height: 18px;
          width: 18px;

          background-size: contain;
          background-repeat: no-repeat;

        }
      }
    }

    .cms {
      h1, h2, h3, h4, h5 {
        margin-bottom: 40px;

        color: $orange;
        font-weight: 900;
      }

      strong {
        font-size: 18px;
      }
    }
  }

  .join-us {
    .phone {
      text-align: center;
      a {
        color: $black;
      }
    }
  }

  .galery {
    .galery-item {
      max-width: 100%;
      margin:0 auto 30px auto; 
    }
  }

}