//_variables.scss
/*
Fonts*/
@include font-face('Neurial Grotesk', '../fonts/neurialgrotesk-regular-webfont', 300, normal, ttf woff2 woff);
@include font-face('Neurial Grotesk Bold', '../fonts/neurialgrotesk-bold-webfont', 700, normal, ttf woff2 woff);
@include font-face('Neurial Grotesk Light', '../fonts/neurialgrotesk-light-webfont', 100, normal, otf woff2 woff);

$light-family: 'Neurial Grotesk Light', sans-serif;
$font-family : 'Neurial Grotesk', sans-serif;
$bold-family: 'Neurial Grotesk Bold', sans-serif;

/*
Couleurs*/
$black:				#000000;
$white:				#FFFFFF;
$cwhite: 			#EBEFF2;
$grey:				#54575A;
$orange: 			#FE5000;
$lightblue: 		#0099FF;
$darkblue: 			#041C2C;

$colors: (
	'black':		#000000,
	'white':		#FFFFFF,
	'cwhite': 		#EBEFF2,
	'grey':			#54575A,
	'text': 		#54575A,
	'orange': 		#FE5000,
	'lightblue': 	#0099FF,
	'darkblue': 	#041C2C
);

/*
Font*/
$font-weight: (
	'400': 			400,
	'500': 			500,
	'600': 			600,
	'700': 			700
);

$font-size: (
	'extra':		11px,
	'regular':		16px,
	'medium':		18px,
	'big':			24px
);

/*
Padding*/
$paddings: (
	'0': 			0px,
	'xs': 			20px,
	'sm': 			40px,
	'md': 			60px,
	'lg': 			80px,
	'xl': 			100px,
	'xxl': 			120px
);

/*
Breakpoints*/

$sm: 				576px;
$md: 				768px;
$lg: 				992px;
$xl: 				1200px;
$xxl:				1420px;